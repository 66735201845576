<template>
  <div>
      <b-table id="all-time-dropdown" :items="headerItems" :fields="fields" show-empty small class="text-center">
        <template #cell(header)="row">
          <div
            class="d-flex justify-content-between flex-lg-row flex-column invoice-spacing align-items-center shadow-lg mt-2 p-2 rounded-lg">
            <!-- Header: Left Content -->
            <div class="text-center">
              <img class="rounded-circle card-text mx-2" :src="
                row.item.avatar &&
                  row.item.avatar.includes('https://ui-avatars.com')
                  ? row.item.avatar
                  : $store.state.appConfig.imageBaseUrl + row.item.avatar
              " alt="img" height="64" width="64" />
            </div>
            <div class="">
              <div class="d-flex flex-row justify-content-between">
                <p class="m-0 table-head-label-text mr-1">company :</p>
                <div class="text-center">
                  <b class="table-head-des-text ">
                    {{ row.item.first_name }}</b>
                </div>

              </div>
              <div class="d-flex flex-row justify-content-between">
                <p class="m-0 table-head-label-text mr-1">industry :</p>
                <div class="text-center">
                  <b class="table-head-des-text">{{
                    row.item.info && row.item.info.industry
                    ? row.item.info.industry.name
                    : row.item.customer && row.item.customer.industry ? row.item.customer.industry.name : "N/A"
                  }}</b>
                </div>

              </div>
            </div>
            <div class="">
              <div class="d-flex flex-row justify-content-between">
                <p class="m-0 table-head-label-text mr-1">Contact No:</p>
                <b class="table-head-des-text">{{ row.item.phone }}</b>
              </div>

              <div class="d-flex flex-row justify-content-between">
                <p class="m-0 table-head-label-text mr-1">ABN:</p>
                <b class="table-head-des-text">{{
                  row.item.info && row.item.info.abn ? row.item.info.abn : row.item.customer && row.item.customer.abn ?
                  row.item.customer.abn : "N/A"
                }}</b>
              </div>
            </div>
            <div class="">
              <div class="d-flex flex-row justify-content-between">
                <p class="m-0 table-head-label-text mr-1">Present Address:</p>
                <b class="table-head-des-text">{{
                  row.item.info && row.item.info.address ? row.item.info.address : row.item.customer &&
                    row.item.customer.address ? row.item.customer.address : "N/A"
                }}</b>
              </div>
              <div class="d-flex flex-row justify-content-between">
                <p class="m-0 table-head-label-text mr-1">Email Add:</p>
                <b class="table-head-des-text">{{ row.item.email }}</b>
              </div>
            </div>
            <div class="p-1 text-center">
              <div class="mx-2 id-text">
                <b class="table-head-des-text">ID #{{ row.item.id }}</b>
              </div>
            </div>
            <div class="py-2 text-center my-dropdown px-2">
              <b-button
                @click="
                  row.toggleDetails();
                getRosterWorkList(row);
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ">
                {{ row.detailsShowing ? "-" : "+" }}
              </b-button>
            </div>
          </div>
        </template>

        <template #row-details="row">
          <b-spinner v-if="loading" class="text-center" variant="primary" label="Text Centered">
          </b-spinner>
          <div v-else class="table-responsive overflow-hidden">
            <sub-table :childKey="masterKey" :status="parentStatus" :subTableItems="row.item.subData"
              @reloadStatus="getRosterWorkList" />
          </div>
        </template>
      </b-table>
    </div>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import {
BAvatar,
BBadge,
BButton,
BCard,
BCol,
BDropdown,
BDropdownItem,
BDropdownItemButton,
BFormCheckbox,
BFormGroup,
BFormInput,
BLink,
BMedia,
BPagination,
BRow,
BSpinner,
BTable,
BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import SubTable from "./sub-table.vue";
export default {
  props: {
    headerItems: {
      type: Array,
      default: () => [],
    },
    parentStatus: Number,
    // masterKey here used to fetch all timesheet data from all timesheet page
    masterKey: Number,
  },
  components: {
    BFormCheckbox,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BMedia,
    BDropdownItem,
    BPagination,
    BTooltip,
    BDropdownItemButton,
    BDropdown,
    BSpinner,
    BTable,
    BAvatar,
    BLink,
    BBadge,
    BCard,
    BFormGroup,
    vSelect,
    SubTable,
  },
  data() {
    return {
      loading: false,
      fields: [
        {
          key: "header",
          label: "",
          sortable: true,
          sortDirection: "desc",
        },
      ],
      pagination: {
        page: 1,
        per_page: 10,
        total: 0,

      },
    };
  },
  methods: {
    async getRosterWorkList(item) {
      this.loading = true;
      if (JSON.parse(getLogedInUser()).roles[0].name === 'superadmin') {
        // query.user_id = this.$route.params.id;
        this.pagination.customer_id = item.item ? item.item.id : item;
      }
      if (JSON.parse(getLogedInUser()).roles[0].name === 'customer') {
        this.pagination.customer_id = item.item ? item.item.id : item;
      }
      if (JSON.parse(getLogedInUser()).roles[0].name === 'admin') {
        this.pagination.customer_id = item.item ? item.item.id : item;
        this.pagination.user_id = JSON.parse(getLogedInUser()).id;
      }
      await this.$store
        .dispatch("roster/getRosterbyId", this.pagination)
        .then((response) => {
          this.pagination.total = response.data.total;
          if (response.status_code == 200) {

            if (item.index === undefined) {
              let index = this.headerItems.indexOf(
                this.headerItems.find((i) => i.id === item)
              );
              this.headerItems[index].subData = response.data.data;
            } else {

              this.headerItems[item.index].subData = response.data.data;
            }
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.label-font {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.id-text {
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
  /* identical to box height */

  /* Blue semi dark */

  color: #264296;
}

.table-head-label-text {
  text-transform: uppercase;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
