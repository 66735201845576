<template>
  <b-row id="time-sheet-sub-table">
    <b-col cols="12" class="table-responsive">
      <b-table
        show-empty
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="subTableItems"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        class="text-center"
      >
        <template #cell(date)="row">
          <p v-if="row.item.status == status">{{ row.item.roster.date }}</p>
          <p v-else-if="childKey == 0 && row.item.status != 0">
            {{ row.item.roster.date }}
          </p>
        </template>
        <template #cell(Employee)="row">
          <p v-if="row.item.status == status">{{ row.item.employee.name }}</p>
          <p v-else-if="childKey == 0 && row.item.status != 0">
            {{ row.item.employee.name }}
          </p>
        </template>
        <template #cell(Job Role)="row">
          <p v-if="row.item.status == status">
            {{
              row.item.roster && row.item.roster.duty.length > 0
                ? row.item.roster.duty[0].name
                : "N/A"
            }}
          </p>
          <p v-else-if="childKey == 0 && row.item.status != 0">
            {{
              row.item.roster && row.item.roster.duty.length > 0
                ? row.item.roster.duty[0].name
                : "N/A"
            }}
          </p>
        </template>
        <template #cell(start)="row">
          <p v-if="row.item.status == status">
            {{ row.item.in_time }}
          </p>
          <p v-else-if="childKey == 0 && row.item.status != 0">
            {{ row.item.in_time }}
          </p>
        </template>
        <template #cell(end)="row">
          <p v-if="row.item.status == status">
            {{ row.item.out_time }}
          </p>
          <p v-else-if="childKey == 0 && row.item.status != 0">
            {{ row.item.out_time }}
          </p>
        </template>

        <template #cell(Action)="row">
          <div v-if="row.item.status == status" class="row">
            <div
              class="col-12 btn-area-padding d-flex flex-row justify-content-center align-items-center"
            >
              <button
                v-b-modal="'modal-center'"
                v-if="row.item.status == 0 && $can('update', 'Timesheet')"
                @click.prevent="getDeclineId(row.item)"
                class="btn btn-success approve-decline"
              >
                Approve
              </button>
              <button
                v-b-modal="'modal-prevent-closing'"
                v-if="row.item.status == 0 && $can('update', 'Timesheet')"
                @click.prevent="getDeclineId(row.item)"
                class="btn mx-1 btn-danger approve-decline"
              >
                Decline
              </button>
              <button
                disabled
                v-if="row.item.status == 1"
                class="btn mx-1 approved-btn"
              >
                Approved
              </button>
              <button
                :id="`tooltip-target-${row.item.id}`"
                v-if="row.item.status == 2"
                class="btn mx-1 declined-btn"
              >
                Declined
                <img src="@/assets/images/icons/alert-circle.png" alt="" />
              </button>
              <b-tooltip
                :target="`tooltip-target-${row.item.id}`"
                triggers="hover"
                variant="danger"
              >
                {{ row.item.note }}
              </b-tooltip>

              <button
                v-if="row.item.status == 1 || row.item.status == 2"
                @click.prevent="rosterWorkApproveEdit(row.item)"
                class="btn border-warning edit-btn"
              >
                <img src="@/assets/images/icons/pen-warning.png" alt="" />
              </button>
              <button
                v-if="
                  row.item.verify == 1 ||
                  (row.item.verify == 2 &&
                    row.item.status == 1 &&
                    $can('update', 'Timesheet'))
                "
                @click.prevent="verify(row.item)"
                class="btn border-warning edit-btn ml-1"
              >
                &check;
              </button>
              <button
                v-if="
                  row.item.verify == 0 ||
                  (row.item.verify == 2 &&
                    row.item.status == 1 &&
                    $can('update', 'Timesheet'))
                "
                @click.prevent="unverify(row.item)"
                class="btn border-warning edit-btn mx-1"
              >
                &cross;
              </button>
            </div>
          </div>
          <div v-else-if="childKey == 0 && row.item.status != 0" class="row">
            <div
              class="col-12 btn-area-padding d-flex flex-row justify-content-center align-items-center"
            >
              <button
                v-b-modal.modal-center
                v-if="row.item.status == 0 && $can('update', 'Timesheet')"
                @click.prevent="getDeclineId(row.item)"
                class="btn btn-success approve-decline"
              >
                Approve
              </button>
              <button
                v-b-modal.modal-prevent-closing
                v-if="row.item.status == 0 && $can('update', 'Timesheet')"
                @click.prevent="getDeclineId(row.item)"
                class="btn mx-1 btn-danger approve-decline"
              >
                Decline
              </button>
              <button
                disabled
                v-if="row.item.status == 1"
                class="btn mx-1 approved-btn"
              >
                Approved
              </button>
              <button
                :id="`tooltip-target-${row.item.id}`"
                v-if="row.item.status == 2"
                class="btn mx-1 declined-btn"
              >
                Declined
                <img src="@/assets/images/icons/alert-circle.png" alt="" />
              </button>
              <b-tooltip
                :target="`tooltip-target-${row.item.id}`"
                triggers="hover"
                variant="danger"
              >
                {{ row.item.note }}
              </b-tooltip>

              <button
                v-if="
                  row.item.status == 1 ||
                  (row.item.status == 2 && $can('update', 'Timesheet'))
                "
                @click.prevent="rosterWorkApproveEdit(row.item)"
                class="btn border-warning edit-btn"
              >
                <img src="@/assets/images/icons/pen-warning.png" alt="" />
              </button>
              <button
                v-if="
                  row.item.verify == 1 ||
                  (row.item.verify == 2 &&
                    row.item.status == 1 &&
                    $can('update', 'Timesheet'))
                "
                @click.prevent="verify(row.item)"
                class="btn border-warning edit-btn ml-1"
              >
                &check;
              </button>
              <button
                v-if="
                  row.item.verify == 0 ||
                  (row.item.verify == 2 &&
                    row.item.status == 1 &&
                    $can('update', 'Timesheet'))
                "
                @click.prevent="unverify(row.item)"
                class="btn border-warning edit-btn mx-1"
              >
                &cross;
              </button>
            </div>
          </div>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12" class="py-2">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="right"
        size="sm"
        class="my-0"
      />
    </b-col>
    <b-modal
      id="modal-prevent-closing"
      centered
      ref="modal"
      hide-footer
      title="Decline Note"
    >
      <form ref="form">
        <div class="row">
          <div class="col-6">
            <p v-if="declinedReason && declinedReason.employee">
              Employee Name : {{ declinedReason.employee.name }}
            </p>
          </div>
          <div class="col-6">
            <p>Employee ID : {{ declinedReason.id }}</p>
          </div>
          <div class="col-6">
            <p>Employee Type : N/A</p>
          </div>
          <div class="col-6">
            <p v-if="declinedReason.roster">
              Timesheet Date : {{ declinedReason.roster.date }}
            </p>
          </div>
        </div>
        <b-form-group
          label="State Reason"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-textarea
            id="name-input"
            v-model="declinedReason.note"
            required
            class="mb-2 bg-light text-decline"
            placeholder="State your reason of declining the timesheet"
          ></b-form-textarea>
        </b-form-group>
        <div class="">
          <button class="btn discard mx-1" @click.prevent="hideModal">
            Discard
          </button>
          <button
            v-if="$can('update', 'Timesheet')"
            class="btn send"
            @click.prevent="rosterWorkDecline(declinedReason)"
          >
            Decline
          </button>
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modal-center"
      centered
      ref="modal"
      hide-footer
      title="Decline Note"
    >
      <b>Are you sure to approve ?</b>
      <div class="text-right">
        <button class="btn discard mx-1" @click.prevent="hideModal">
          Discard
        </button>
        <button
          v-if="$can('update', 'Timesheet')"
          class="btn send"
          @click.prevent="rosterWorkApprove(approve)"
        >
          Approve
        </button>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  props: {
    subTableItems: {
      type: Array,
      default: () => [],
    },
    status: Number,
    // childKey here used to fetch all timesheet data from all timesheet page
    childKey: Number,
  },
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BTooltip,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "fields",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      fields: [
        { key: "date", sortable: true },
        { key: "Employee", sortable: true },
        { key: "Job Role", sortable: true },
        { key: "Start", sortable: true },
        { key: "End", sortable: true },
        { key: "Action", sortable: false },
      ],
      declinedReason: [
        {
          employee: { name: "" },
        },
      ],
      approve: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-prevent-closing", "#btnShow");
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    rosterWorkApprove(item) {
      this.$store
        .dispatch("roster/rosterWorkApprove", {
          id: item.id,
          customer_id: item.roster.customer_id,
          status: 1,
        })
        .then((response) => {
          this.getFromParent(item.roster.customer_id);
        });
    },
    rosterWorkDecline(item) {
      this.$store
        .dispatch("roster/rosterWorkApprove", {
          id: item.id,
          customer_id: item.roster.customer_id,
          status: 2,
          note: item.note,
        })
        .then((response) => {
          this.getFromParent(item.roster.customer_id);
        });
    },
    rosterWorkApproveEdit(item) {
      this.$store
        .dispatch("roster/rosterWorkApprove", {
          id: item.id,
          customer_id: item.roster.customer_id,
          status: 0,
          // verify: 2,
        })
        .then((response) => {
          this.getFromParent(item.roster.customer_id);
        });
    },
    verify(item) {
      this.$store
        .dispatch("roster/rosterWorkApprove", {
          id: item.id,
          customer_id: item.roster.customer_id,
          verify: 1,
          note: "test",
        })
        .then((response) => {
          this.getFromParent(item.roster.customer_id);
        });
    },
    unverify(item) {
      this.$store
        .dispatch("roster/rosterWorkApprove", {
          id: item.id,
          customer_id: item.roster.customer_id,
          verify: 0,
          note: "test",
        })
        .then((response) => {
          this.getFromParent(item.roster.customer_id);
        });
    },
    getFromParent(customer_id) {
      this.$emit("reloadStatus", customer_id);
    },
    getDeclineId(item) {
      this.declinedReason = item;
      this.approve = item;
    },
  },
};
</script>
<style scoped>
.text-decline {
  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.edit-btn {
  color: #ff9d43;
}

.declined-btn {
  background: rgba(255, 79, 93, 0.1);
  color: #ff4f5d !important;
  border: 1px solid #ff4f5d;
  border-radius: 4px;
  padding-left: 43px;
  padding-right: 43px;
}

.approved-btn {
  background: rgba(41, 167, 74, 0.1);
  border: 1px solid #29a74a;
  border-radius: 4px;
  color: #29a74a !important;
  padding-left: 50px;
  padding-right: 50px;
}

.approve-decline {
  padding-left: 40px;
  padding-right: 40px;
}

.send {
  /* Gradient */

  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}

.discard {
  background: rgba(255, 79, 93, 0.1);
  border: 1px solid #ff4f5d;
  border-radius: 5px;
  color: #ff4f5d;
}

.btn-area-padding {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
