<template>
  <section>
    <b-card>
      <b-row>
        <b-col cols="12">
          <div>
            <div class="d-flex flex-row justify-content-between">
              <p class="header-title">All Timesheets</p>
              <div class="d-block ml-auto">
                <router-link
                  v-if="$can('create', 'Timesheet')"
                  to="/time-sheets/create"
                >
                  <b-button variant="primary" class="btn mt-1 mt-lg-0 add-btn">
                    <span class="pr-1"> Create Timesheet </span>
                  </b-button>
                </router-link>
                <b-button
                  v-if="rosterWorks.length > 0"
                  variant="primary"
                  class="btn add-btn"
                  @click.prevent="handleDownload('all')"
                >
                  <span> Export All</span>
                </b-button>
                <b-button
                  v-if="rosterWorks.length > 0"
                  variant="primary"
                  class="btn add-btn"
                  @click.prevent="handleDownload('filtered')"
                >
                  <span> Export Filtered</span>
                </b-button>
                <b-button
                  v-if="
                    ids.length > 1 &&
                    ($can('approve', 'Timesheet') ||
                      $can('verify', 'Timesheet'))
                  "
                  variant="success"
                  class="btn add-btn"
                  v-b-modal.modal-approve-all
                >
                  <span class="d-flex">
                    <span v-if="logedInUser.roles[0].name === 'customer'"
                      >Approve All</span
                    >
                    <span v-if="logedInUser.roles[0].name === 'admin'"
                      >Verify All</span
                    >
                  </span>
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="row">
            <!-- <div
              v-if="
                logedInUser.roles[0].name === 'employee' ||
                logedInUser.roles[0].name === 'superadmin'
              "
              :class="
                logedInUser.roles[0].name === 'customer'
                  ? 'col-md-3'
                  : 'col-md-3'
              "
            >
              <label for="" class="label-font">Subsidiary Company</label>
              <b-form-group>
                <v-select
                  v-model="query.company"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="companies"
                  label="name"
                  placeholder="Select Subsidiary Company"
                  @input="
                    getCustomers();
                    getRosterWorkList();
                  "
                />
              </b-form-group>
            </div> -->
            <!-- <div
              v-if="logedInUser.roles[0].name !== 'customer'"
              :class="
                logedInUser.roles[0].name === 'customer'
                  ? 'col-md-3'
                  : 'col-md-3'
              "
            >
              <label for="" class="label-font">Client</label>
              <b-form-group>
                <v-select
                  v-model="query.customer"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="customers"
                  label="name"
                  placeholder="Select Client"
                  @input="getRosterWorkList"
                />
              </b-form-group>
            </div> -->

            <div
              :class="
                logedInUser.roles[0].name === 'customer'
                  ? 'col-md-3'
                  : 'col-md-3'
              "
              v-if="logedInUser.roles[0].name !== 'employee'"
            >
              <label for="" class="label-font">Search</label>
              <b-form-group class="border rounded">
                <b-form-input
                  v-debounce:300="getRosterWorkList"
                  v-model="query.employee"
                  class="rounded data-text"
                  placeholder="Search By Employee Name"
                  type="text"
                  size="md"
                />
              </b-form-group>
            </div>
            <div
              :class="
                logedInUser.roles[0].name === 'customer'
                  ? 'col-md-3'
                  : 'col-md-3'
              "
            >
              <label for="" class="label-font">Status</label>
              <b-form-group>
                <v-select
                  v-model="query.c_status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statuses"
                  label="name"
                  placeholder="Select Status"
                  @input="getRosterWorkList"
                />
              </b-form-group>
            </div>
            <div
              :class="
                logedInUser.roles[0].name === 'customer'
                  ? 'col-md-3'
                  : 'col-md-3'
              "
              v-if="logedInUser.roles[0].name === 'customer'"
            >
              <label for="" class="label-font">Job Category </label>
              <b-form-group>
                <v-select
                  v-model="query.duty"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="duties"
                  label="name"
                  placeholder="Select Job Category"
                  @input="getRosterWorkList"
                />
              </b-form-group>
            </div>
            <div
              :class="
                logedInUser.roles[0].name === 'customer'
                  ? 'col-md-3'
                  : 'col-md-3'
              "
            >
              <label for="" class="label-font">Filter By Date</label>
              <b-form-group class="my-date-range">
                <flat-pickr
                  v-model="rangeDate"
                  class="form-control bg-light border-dark-1"
                  :config="{ mode: 'range', wrap: true, altInput: true }"
                  placeholder="Select Date Range & Filter"
                  v-debounce:1000="getRosterWorkList"
                />
                <button
                  v-if="rangeDate"
                  class="btn btn-sm btn-danger text-end d-block ml-1"
                  type="button"
                  title="Clear"
                  data-clear
                  @click="clearDate"
                >
                  <span>X</span>
                </button>
              </b-form-group>
            </div>
          </div>

          <div
            v-if="loading"
            class="d-flex justify-content-center align-items-center vh-100 mb-3"
          >
            <b-spinner label="Loading..."></b-spinner>
          </div>

          <div v-else class="mt-2">
            <div class="row">
              <div class="col-md-8">
                <div class="bar-indicator">
                  <h4>Bar Indicator</h4>
                  <div class="bars">
                    <div class="d-flex">
                      <span>Roster Hours:</span>
                      <div class="ind roster"></div>
                      |
                    </div>
                    <div class="d-flex mx-1">
                      <span>Work Hours:</span>
                      <div class="ind work"></div>
                      |
                    </div>
                    <div class="d-flex mr-1">
                      <span>Work Before Roster Hours:</span>
                      <div class="ind before"></div>
                      |
                    </div>
                    <div class="d-flex">
                      <span>Work After Roster Hours:</span>
                      <div class="ind after"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label for="" class="label-font"
                  >Filter By Weekend & Weekdays</label
                >
                <b-form-group>
                  <v-select
                    v-model="query.week"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="weekOptions"
                    label="name"
                    placeholder="Filter By Weekend & Weekdays"
                    @input="getRosterWorkList"
                  />
                </b-form-group>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-md-12">
                <div class="dot-indicator">
                  <h4>Dot Icon Indicator</h4>
                  <div class="bars">
                    <div class="d-flex align-items-center">
                      <span class="">Roster Start</span>
                      <img
                        src="../../assets/images/icons/RS.png"
                        class="mx-1"
                      />
                      |
                    </div>
                    <div class="d-flex align-items-center mx-1">
                      <span>Roster End</span>
                      <img
                        src="../../assets/images/icons/RE.png"
                        class="mx-1"
                      />
                    </div>
                    <div class="d-flex align-items-center">
                      <span>Work Start</span>
                      <img
                        src="../../assets/images/icons/WS.png"
                        class="mx-1"
                      />
                      |
                    </div>
                    <div class="d-flex align-items-center mx-1">
                      <span>Work End</span>
                      <img
                        src="../../assets/images/icons/WE.png"
                        class="mx-1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <b-table
              striped
              hover
              :items="rosterWorks"
              :fields="fields"
              :busy="loading"
            >
              <template #cell(SL)="row">
                <span> {{ form + row.index }}</span>
              </template>
              <template #cell(date)="row">
                <span> {{ formateDate(row.item.date) }}</span>
              </template>
              <template #cell(employee)="row">
                <span>{{
                  row.item.employee ? row.item.employee.name : "N/A"
                }}</span>
              </template>
              <template
                v-if="logedInUser.roles[0].name !== 'employee'"
                #cell(company)="row"
              >
                <span>{{ row.item.roster.company.name }}</span>
              </template>
              <template v-else #cell(assignedBy)="row">
                <span>{{ row.item.roster.company.name }}</span>
              </template>
              <template
                v-if="logedInUser.roles[0].name !== 'employee'"
                #cell(client)="row"
              >
                <span>{{
                  row.item.roster && row.item.roster.customer
                    ? row.item.roster.customer.name
                    : "N/A"
                }}</span>
              </template>
              <template v-else #cell(assignedTo)="row">
                <span>{{
                  row.item.roster && row.item.roster.customer
                    ? row.item.roster.customer.name
                    : "N/A"
                }}</span>
              </template>

              <template #cell(in)="row">
                <span>{{ formateTime(row.item.in_time) }}</span>
              </template>
              <template #cell(out)="row">
                <span>{{
                  row.item.out_time === row.item.in_time
                    ? "---"
                    : formateTime(row.item.out_time)
                }}</span>
              </template>
              <template #cell(shift)="row">
                <span>{{ row.item.shift_type.shift }}</span>
              </template>
              <template #cell(shift_hours)="row">
                <span
                  v-if="
                    logedInUser.roles[0].name === 'admin' ||
                    logedInUser.roles[0].name === 'superadmin'
                  "
                  >{{ row.item.hours.adminOrdinary }}
                </span>
                <span v-else>{{ row.item.hours.ordinary }} </span>
              </template>
              <template #cell(m)="row">
                <div
                  v-if="
                    logedInUser.roles[0].name === 'admin' ||
                    logedInUser.roles[0].name === 'superadmin'
                  "
                >
                  <span
                    v-if="row.item.shift_type.shift.toLowerCase() == 'morning'"
                    >{{ makeFloat(row.item.shift_type.deduct_hours) }}</span
                  >
                  <span
                    v-else-if="
                      row.item.shift_type.next.next_shift.toLowerCase() ==
                      'morning'
                    "
                    >{{ makeFloat(row.item.shift_type.deduct_next) }}</span
                  >
                  <span v-else>0.00</span>
                </div>
                <div v-else>
                  <span
                    v-if="row.item.shift_type.shift.toLowerCase() == 'morning'"
                    >{{ makeFloat(row.item.shift_type.hours) }}</span
                  >
                  <span
                    v-else-if="
                      row.item.shift_type.next.next_shift.toLowerCase() ==
                      'morning'
                    "
                    >{{ makeFloat(row.item.shift_type.next.hours) }}</span
                  >
                  <span v-else>0.00</span>
                </div>
              </template>
              <template #cell(a)="row">
                <div
                  v-if="
                    logedInUser.roles[0].name === 'admin' ||
                    logedInUser.roles[0].name === 'superadmin'
                  "
                >
                  <span
                    v-if="
                      row.item.shift_type.shift.toLowerCase() == 'afternoon'
                    "
                    >{{ makeFloat(row.item.shift_type.deduct_hours) }}</span
                  >
                  <span
                    v-else-if="
                      row.item.shift_type.next.next_shift.toLowerCase() ==
                      'afternoon'
                    "
                    >{{ makeFloat(row.item.shift_type.deduct_next) }}</span
                  >
                  <span v-else>0.00</span>
                </div>
                <div v-else>
                  <span
                    v-if="
                      row.item.shift_type.shift.toLowerCase() == 'afternoon'
                    "
                    >{{ makeFloat(row.item.shift_type.hours) }}</span
                  >
                  <span
                    v-else-if="
                      row.item.shift_type.next.next_shift.toLowerCase() ==
                      'afternoon'
                    "
                    >{{ makeFloat(row.item.shift_type.next.hours) }}</span
                  >
                  <span v-else>0.00</span>
                </div>
              </template>
              <template #cell(n)="row">
                <div
                  v-if="
                    logedInUser.roles[0].name === 'admin' ||
                    logedInUser.roles[0].name === 'superadmin'
                  "
                >
                  <span
                    v-if="row.item.shift_type.shift.toLowerCase() == 'night'"
                    >{{ makeFloat(row.item.shift_type.deduct_hours) }}</span
                  >
                  <span
                    v-else-if="
                      row.item.shift_type.next.next_shift.toLowerCase() ==
                      'night'
                    "
                    >{{ makeFloat(row.item.shift_type.deduct_next) }}</span
                  >
                  <span v-else>0.00</span>
                </div>
                <div v-else>
                  <span
                    v-if="row.item.shift_type.shift.toLowerCase() == 'night'"
                    >{{ makeFloat(row.item.shift_type.hours) }}</span
                  >
                  <span
                    v-else-if="
                      row.item.shift_type.next.next_shift.toLowerCase() ==
                      'night'
                    "
                    >{{ makeFloat(row.item.shift_type.next.hours) }}</span
                  >
                  <span v-else>0.00</span>
                </div>
              </template>

              <template #cell(ot_1)="row">
                <span
                  v-if="
                    logedInUser.roles[0].name === 'admin' ||
                    logedInUser.roles[0].name === 'superadmin'
                  "
                  >{{ row.item.hours.adminOtOne }}
                </span>
                <span v-else>{{ row.item.hours.otOne }} </span>
              </template>
              <template #cell(ot_2)="row">
                <span
                  v-if="
                    logedInUser.roles[0].name === 'admin' ||
                    logedInUser.roles[0].name === 'superadmin'
                  "
                  >{{ row.item.hours.adminOtTwo }}
                </span>
                <span v-else>{{ row.item.hours.otTwo }} </span>
              </template>
              <template #cell(total_hours)="row">
                <span
                  v-if="
                    logedInUser.roles[0].name === 'admin' ||
                    logedInUser.roles[0].name === 'superadmin'
                  "
                  >{{
                    getSum(
                      row.item.hours.adminOrdinary,
                      row.item.hours.adminOtOne,
                      row.item.hours.adminOtTwo
                    )
                  }}
                </span>
                <span v-else
                  >{{
                    getSum(
                      row.item.hours.ordinary,
                      row.item.hours.otOne,
                      row.item.hours.otTwo
                    )
                  }}
                </span>
              </template>
              <template #cell(status)="row">
                <b-button
                  v-if="row.item.status === 2"
                  v-b-tooltip.hover.v-danger
                  :title="row.item.note"
                  variant="danger"
                  size="sm"
                  class="btn add-btn d-block status-btn"
                >
                  <span class="d-flex justify-content-center">
                    <span style="margin-right: 5px">Declined </span>
                    <alert-circle-icon
                      size="1x"
                      class="custom-class"
                    ></alert-circle-icon>
                  </span>
                </b-button>
                <b-button
                  v-if="row.item.status === 1 && row.item.verify === 1"
                  variant="success"
                  size="sm"
                  class="btn add-btn d-block status-btn"
                >
                  <span class="d-flex justify-content-center">
                    <span>Approved</span>
                  </span>
                </b-button>
                <b-button
                  v-if="row.item.status === 1 && row.item.verify === 0"
                  variant="success"
                  size="sm"
                  class="btn add-btn d-block status-btn"
                >
                  <span class="d-flex justify-content-center">
                    <span>Approved</span>
                  </span>
                </b-button>
                <b-button
                  v-if="row.item.status === 0"
                  variant="warning"
                  size="sm"
                  class="btn add-btn d-block status-btn"
                >
                  <span class="d-flex justify-content-center">
                    <span>Pending</span>
                  </span>
                </b-button>
              </template>
              <template #cell(action)="row">
                <div class="d-flex">
                  <b-button
                    @click.prevent="showSigninSignout(row.item)"
                    title="Signin Signout Activity"
                    variant="secondary"
                    size="sm"
                    class="btn add-btn d-block"
                  >
                    <activity-icon
                      size="1x"
                      class="custom-class"
                    ></activity-icon>
                  </b-button>
                  <router-link
                    v-if="$can('update', 'Timesheet')"
                    :to="`/time-sheets/edit/${row.item.id}`"
                  >
                    <b-button
                      v-b-tooltip.hover
                      title="Edit"
                      variant="primary"
                      size="sm"
                      class="btn add-btn d-block"
                    >
                      <edit-3-icon size="1x" class="custom-class"></edit-3-icon>
                    </b-button>
                  </router-link>
                  <b-button
                    v-if="row.item.status === 0 && $can('approve', 'Timesheet')"
                    v-b-tooltip.hover
                    title="Approve"
                    variant="info"
                    size="sm"
                    class="btn add-btn d-block"
                    @click.prevent="rosterWorkStatusUpdate(row.item, 1, 0)"
                  >
                    <check-icon size="1x" class="custom-class"></check-icon>
                  </b-button>
                  <b-button
                    v-if="row.item.status === 0 && $can('decline', 'Timesheet')"
                    v-b-tooltip.hover
                    title="Decline"
                    variant="danger"
                    size="sm"
                    class="btn add-btn d-block"
                    @click.prevent="declineRosterWork(row.item)"
                  >
                    <x-icon size="1x" class="custom-class"></x-icon>
                  </b-button>
                  <b-button
                    v-if="
                      row.item.status === 1 &&
                      row.item.verify === 0 &&
                      $can('verify', 'Timesheet')
                    "
                    v-b-tooltip.hover
                    title="Send To Xero"
                    variant="success"
                    size="sm"
                    class="btn add-btn d-block"
                    @click.prevent="rosterWorkStatusUpdate(row.item, 1, 1)"
                  >
                    <send-icon size="1x" class="custom-class"></send-icon>
                  </b-button>
                </div>
              </template>
              <template #row-details="row">
                <div>
                  <vue-slider
                    v-model="row.item.value"
                    :process="row.item.process2"
                    :railStyle="{ backgroundColor: '#ea5455' }"
                    :tooltip="'active'"
                    :height="10"
                    :disabled="true"
                    :min="row.item.min"
                    :max="row.item.max"
                  >
                    <template v-slot:dot="dot">
                      <img
                        v-if="
                          row.item.data[dot.index].startsWith('Work Start At')
                        "
                        src="../../assets/images/icons/WS.png"
                        class="custom-dot"
                      />
                      <img
                        v-if="
                          row.item.data[dot.index].startsWith('Roster Start At')
                        "
                        src="../../assets/images/icons/RS.png"
                        class="custom-dot"
                      />
                      <img
                        v-if="
                          row.item.data[dot.index].startsWith('Roster End At')
                        "
                        src="../../assets/images/icons/RE.png"
                        class="custom-dot"
                      />
                      <img
                        v-if="
                          row.item.data[dot.index].startsWith('Work End At')
                        "
                        src="../../assets/images/icons/WE.png"
                        :class="`${row.item.in_time === row.item.out_time ? 'd-none' : ''} custom-dot`"
                      />
                    </template>

                    <template #tooltip="{ index }">
                      <div>{{ row.item.data[index] }}</div>
                    </template>
                  </vue-slider>
                </div>
              </template>
            </b-table>
          </div>

          <b-pagination
            class="mt-1 p-1 d-flex justify-content-end bg-white"
            v-if="total > query.per_page"
            v-model="query.page"
            :total-rows="total"
            :per-page="query.per_page"
            aria-controls="my-table"
            @change="getRosterWorkList($event)"
            pills
            size="lg"
            page-class="info"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
    <!-- timesheet decline modal -->
    <b-modal
      id="modal-prevent-closing"
      centered
      ref="modal-prevent-closing"
      hide-footer
      title="Decline Note"
    >
      <form ref="form">
        <div class="row">
          <div class="col-6">
            <p
              class="decline-text"
              v-if="declinedReason && declinedReason.employee"
            >
              Employee Name : {{ declinedReason.employee.name }}
            </p>
          </div>
          <div class="col-6">
            <p class="decline-text">Employee ID : {{ declinedReason.id }}</p>
          </div>
          <!-- <div class="col-6">
            <p>Employee Type : N/A</p>
          </div> -->
          <div class="col-6">
            <p class="decline-text" v-if="declinedReason.roster">
              Timesheet Date : {{ formateDate(declinedReason.roster.date) }}
            </p>
          </div>
        </div>
        <b-form-group
          label="Decline Note"
          label-for="name-input"
          invalid-feedback="Decline note is required"
        >
          <b-form-textarea
            id="name-input"
            v-model="declinedReason.note"
            required
            class="mb-2 bg-light text-decline"
            placeholder="State your reason of declining the timesheet"
          ></b-form-textarea>
        </b-form-group>
        <div class="d-flex justify-content-end">
          <button class="btn discard mx-1" @click.prevent="hideModal">
            Cancel
          </button>
          <button
            v-if="$can('decline', 'Timesheet')"
            class="btn send"
            @click.prevent="rosterWorkStatusUpdate(declinedReason, 2, 0)"
          >
            Decline
          </button>
        </div>
      </form>
    </b-modal>
    <!-- <span v-if="logedInUser.roles[0].name === 'customer'">Approve All</span> -->

    <b-modal
      :ok-title="
        logedInUser.roles[0].name === 'customer' ? 'Approve All' : 'Verify All'
      "
      @ok="handleOk"
      id="modal-approve-all"
      :title="
        logedInUser.roles[0].name === 'customer'
          ? 'Approve All The Timesheets.'
          : 'Verify All The Timesheets.'
      "
    >
      <h4 class="my-1">
        Are You Sure To
        {{ logedInUser.roles[0].name === "customer" ? "Approve" : "Verify" }}
        All The Timesheets?
      </h4>
    </b-modal>
    <b-modal
      v-model="showSigninSignoutDetailsModal"
      ok-only
      ok-title="Close"
      size="lg"
      centered
      title="Signin / Signout Details"
    >
      <b-table striped hover :items="workDetails" :fields="detailsFields">
        <template #cell(SL)="row">
          <span> {{ row.index + 1 }}</span>
        </template>
        <template #cell(Date)="row">
          <span> {{ formateDate(row.item.in_time) }}</span>
        </template>
        <template #cell(Signin)="row">
          <span> {{ formateTime(row.item.in_time) }}</span>
        </template>
        <template #cell(Signout)="row">
          <span> {{ formateTime(row.item.out_time) }}</span>
        </template>
        <template #cell(Hours)="row">
          <span> {{ row.item.hours }} hours</span>
        </template>
      </b-table>
    </b-modal>
  </section>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import { calculateTotalHours } from "@/utils/calculation";
import { toast } from "@/utils/toast";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BModal,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  VBTooltip,
  BTr,
  BTh,
  BTd,
  BProgress,
  BProgressBar,
} from "bootstrap-vue";
import moment from "moment";
import {
  AlertCircleIcon,
  CheckIcon,
  Edit3Icon,
  SendIcon,
  XIcon,
  ActivityIcon,
} from "vue-feather-icons";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import TimeSheetTableCompnent from "./components/time-sheet-table.vue";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    TimeSheetTableCompnent,
    BTable,
    BFormTextarea,
    BModal,
    BTr,
    BTh,
    BTd,
    BProgress,
    BProgressBar,
    CheckIcon,
    SendIcon,
    XIcon,
    Edit3Icon,
    AlertCircleIcon,
    ActivityIcon,
    vSelect,
    BPagination,
    BCard,
    flatPickr,
    VueSlider,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      loading: false,
      rosterWorks: [],
      exportData: [],
      rangeDate: null,
      total: 0,
      form: 0,
      to: 0,
      value: 100,
      max: 100,
      lists: [],
      showSigninSignoutDetailsModal: false,
      workDetails: [],
      weekOptions: [
        { id: 1, name: "Weekend" },
        { id: 0, name: "Weekdays" },
      ],
      statuses: [
        { id: 0, name: "Pending" },
        { id: 1, name: "Approved" },
        { id: 2, name: "Declined" },
        // { id: 3, name: "Sent To Xero"}
      ],
      // masterKey here used to fetch all timesheet data from all timesheet page
      masterKey: 0,
      query: {
        name: "",
        search: "",
        company: null,
        id: "",
        industry_id: "",
        page: 1,
        per_page: 20,
        weekends: null,
      },
      industries: [],
      companies: [],
      customers: [],
      ids: [],
      duties: [],
      declinedReason: {
        id: "",
        note: "",
        employee: {},
        roster: {},
      },
      logedInUser: JSON.parse(getLogedInUser()),
      exportHeader: [
        "id",
        "date",
        // JSON.parse(getLogedInUser()).roles[0].name === "admin"
        //   ? null
        //   : JSON.parse(getLogedInUser()).roles[0].name !== "employee"
        //   ? "company"
        //   : "assignedBy",

        // JSON.parse(getLogedInUser()).roles[0].name === "customer"
        //   ? ""
        //   : JSON.parse(getLogedInUser()).roles[0].name !== "employee"
        //   ? "client"
        //   : "assignedTo",
        JSON.parse(getLogedInUser()).roles[0].name !== "employee"
          ? "employee"
          : "",
        "in_time",
        "out_time",
        "shift",
        "shift_hours",
        // "morning",
        // "afternoon",
        // "night",
        "ot_1",
        "ot_2",
        "total_hours",
        "status",
        "week_status",
      ],
      exportVal: [
        "id",
        "date",
        // JSON.parse(getLogedInUser()).roles[0].name === "admin" ? null : "user",
        // JSON.parse(getLogedInUser()).roles[0].name === "customer"
        //   ? null
        //   : "customer",
        JSON.parse(getLogedInUser()).roles[0].name !== "employee"
          ? "employee"
          : "",
        "in_time",
        "out_time",
        "shift",
        "ordinary",
        // "morning",
        // "afternoon",
        // "night",
        "otOne",
        "otTwo",
        "total_hours",
        "status",
        "week_status",
      ],
      fields: [
        "SL",
        "date",
        // JSON.parse(getLogedInUser()).roles[0].name === "admin"
        //   ? ""
        //   : JSON.parse(getLogedInUser()).roles[0].name !== "employee"
        //   ? "company"
        //   : "assignedBy",

        // JSON.parse(getLogedInUser()).roles[0].name === "customer"
        //   ? ""
        //   : JSON.parse(getLogedInUser()).roles[0].name !== "employee"
        //   ? "client"
        //   : "assignedTo",
        JSON.parse(getLogedInUser()).roles[0].name !== "employee"
          ? "employee"
          : "",
        "in",
        "out",
        "shift",
        "shift_hours",
        // "m",
        // "a",
        // "n",
        "ot_1",
        "ot_2",
        "total_hours",
        "status",
        "action",
      ],
      detailsFields: ["SL", "Date", "Signin", "Signout", "Hours"],
    };
  },
  created() {
    if (this.$route.query.id && this.$route.query.name) {
      this.query.c_status = {
        id: this.$route.query.id,
        name: this.$route.query.name,
      };
    }
    this.getRosterWorkList();
    if (this.logedInUser.roles[0].name == "employee") {
      this.getEmployeeAssignedToAssignedByList();
    } else {
      this.getCompanies();
    }
  },
  mounted() {
    if (this.logedInUser.roles[0].name == "admin") {
      this.getCustomers();
    }
    if (this.logedInUser.roles[0].name == "customer") {
      this.getDuties();
    }
  },

  methods: {
    async getDuties() {
      let query = {
        per_page: 1000,
      };
      let res = await this.$store.dispatch("duty/getDuties", query);
      this.duties = res.data.data;
    },
    showSigninSignout(item) {
      this.showSigninSignoutDetailsModal = true;
      this.workDetails = item.work_details;
    },
    clearDate() {
      this.rangeDate = null;
      this.query.date = "";
      this.query.end = "";
      this.getRosterWorkList();
    },
    makeFloat(number) {
      return parseFloat(number).toFixed(2);
    },
    getSum(ordinary, otOne, otTwo) {
      return calculateTotalHours(ordinary, otOne, otTwo);
    },
    startsWith(data, text) {
      // if any array item data is start with text then return
      // true otherwise return false
      data.forEach((element, index) => {
        if (element.startsWith(text)) {
          return index;
        } else {
          return false;
        }
      });
    },
    getEmployeeAssignedToAssignedByList() {
      this.$store
        .dispatch(
          "employee/getEmployeeAssignedToAssignedByList",
          this.$route.params.id
        )
        .then((res) => {
          let data = res.data.data;
          if (data.employee_customer.length > 0) {
            this.clients = data.employee_customer;
          }
          if (data.employee_to_company.length > 0) {
            this.companies = data.employee_to_company;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-prevent-closing", "#btnShow");
    },
    formateTime(time) {
      // format time to 12 hours with AM/PM
      let hour = moment(time).format("hh:mm A");
      if (hour == "Invalid date") {
        return "N/A";
      }
      return hour;
    },
    formateDate(date) {
      // format date to 12th Aug, 2022
      return moment(date).format("DD-MM-YYYY");
    },
    async getRosterWorkList(e) {
      this.loading = true;
      if (e && typeof e === "number") {
        this.query.page = e;
      } else {
        this.query.page = 1;
      }

      if (this.query.customer) {
        this.query.customer_id = this.query.customer.id;
      } else {
        this.query.customer_id = "";
      }
      if (this.query.c_status) {
        this.query.status = this.query.c_status.id;
      } else {
        this.query.status = "";
      }
      if (this.query.week) {
        this.query.weekends = this.query.week.id;
      } else {
        this.query.weekends = null;
      }
      if (this.query.duty) {
        this.query.duty_id = this.query.duty.id;
        // this.query.duty = ""
      } else {
        this.query.duty_id = "";
      }
      if (this.query.company && this.query.company.id) {
        this.query.user_id = this.query.company.id;
        this.query.company_id = this.query.company.id;
      } else {
        this.query.user_id = "";
        this.query.company_id = "";
      }
      if (this.logedInUser.roles[0].name == "customer") {
        this.query.customer_id = this.logedInUser.id;
      }
      if (this.logedInUser.roles[0].name === "admin") {
        this.query.user_id = this.logedInUser.id;
        this.query.company_id = this.logedInUser.id;
      }
      if (this.logedInUser.roles[0].name == "employee") {
        this.query.employee_id = this.logedInUser.id;
      }
      if (this.rangeDate && this.rangeDate.length > 20) {
        // split the string in arrar
        let arr = this.rangeDate.split(" to ");
        this.query.date = arr[0];
        this.query.end = arr[1];
      } else {
        this.query.date = "";
        this.query.end = "";
      }
      await this.$store
        .dispatch("roster/getRosterbyId", this.query)
        .then((response) => {
          if (response.status_code == 200) {
            this.rosterWorks = response.data.data;
            // add a new property to the object
            this.rosterWorks.forEach((element) => {
              if (element.weekend === 1) {
                element._rowVariant = "danger";
              }

              if (element.details && element.details.date) {
                element._showDetails = true;

                const rosterStartTime = moment(
                  element.details.start_time
                ).format("HH:mm");
                const rosterEndTime = moment(element.details.end_time).format(
                  "HH:mm"
                );
                const inTime = moment(element.in_time).format("HH:mm");
                const outTime = moment(element.out_time).format("HH:mm");

                // check rosterStartTime is greater than inTime
                if (rosterStartTime > inTime && rosterEndTime < outTime) {
                  const workStartBeforeRosterStart = moment(
                    element.details.start_time
                  ).diff(moment(element.in_time), "hours", true);
                  const workEndAfterRosterEnd = moment(element.out_time).diff(
                    moment(element.details.end_time),
                    "hours",
                    true
                  );
                  const inTimeInPercentage =
                    (workStartBeforeRosterStart * 100) / element.details.hours;
                  const outTimeInPercentage =
                    (workEndAfterRosterEnd * 100) / element.details.hours;
                  element.value = [
                    0 - Math.round(inTimeInPercentage),
                    0,
                    100,
                    100 + Math.round(outTimeInPercentage),
                  ];
                  element.min = 0 - Math.round(inTimeInPercentage);
                  element.max = 100 + Math.round(outTimeInPercentage);
                  element.data = [
                    "Work Start At " +
                      moment(element.in_time).format("hh:mm A"),
                    "Roster Start At " +
                      moment(element.details.start_time).format("hh:mm A"),
                    "Roster End At " +
                      moment(element.details.end_time).format("hh:mm A"),
                    "Work End At " + moment(element.out_time).format("hh:mm A"),
                  ];
                  element.process2 = (dotsPos) => [
                    [dotsPos[0], dotsPos[1], { backgroundColor: "#ffc107" }],
                    [dotsPos[1], dotsPos[2], { backgroundColor: "#28a745" }],
                    [dotsPos[2], dotsPos[3], { backgroundColor: "#dc3545" }],
                  ];
                } else if (rosterStartTime > inTime) {
                  const workStartBeforeRosterStart = moment(
                    element.details.start_time
                  ).diff(moment(element.in_time), "hours", true);
                  const inTimeInPercentage =
                    (workStartBeforeRosterStart * 100) / element.details.hours;
                  element.value = [0 - Math.round(inTimeInPercentage), 0, 100];
                  element.min = 0 - Math.round(inTimeInPercentage);
                  element.max = 100;
                  element.data = [
                    "Work Start At " +
                      moment(element.in_time).format("hh:mm A"),
                    "Roster Start At " +
                      moment(element.details.start_time).format("hh:mm A"),
                    "Roster End At " +
                      moment(element.details.end_time).format("hh:mm A"),
                  ];
                  element.process2 = (dotsPos) => [
                    [dotsPos[0], dotsPos[1], { backgroundColor: "#ffc107" }],
                    [dotsPos[1], dotsPos[2], { backgroundColor: "#28a745" }],
                  ];
                } else if (rosterEndTime < outTime) {
                  const workStartAfterRosterStart = moment(
                    element.in_time
                  ).diff(moment(element.details.start_time), "hours", true);
                  const workEndAfterRosterStart = moment(
                    element.out_time
                  ).diff(moment(element.details.end_time), "hours", true);
                  const inTimeInPercentage =
                    (workStartAfterRosterStart * 100) / element.details.hours;
                  const outTimeInPercentage =
                    (workEndAfterRosterStart * 100) / element.details.hours;
                  element.max = 100 + Math.round(outTimeInPercentage);
                  element.min = 0;

                  element.value = [
                    0,
                    0 + Math.round(inTimeInPercentage),
                    100,
                    100 + Math.round(outTimeInPercentage),
                  ];
                  element.data = [
                    "Roster Start At " +
                      moment(element.details.start_time).format("hh:mm A"),
                    "Work Start At " + moment(element.in_time).format("hh:mm A"),
                    "Roster End At " +
                      moment(element.details.end_time).format("hh:mm A"),
                    "Work End At " + moment(element.out_time).format("hh:mm A"),
                  ];
                  element.process2 = (dotsPos) => [
                    [dotsPos[0], dotsPos[1], { backgroundColor: "#17a2b8" }],
                    [dotsPos[1], dotsPos[2], { backgroundColor: "#28a745" }],
                    [dotsPos[2], dotsPos[3], { backgroundColor: "#dc3545" }],
                  ];
                } else {
                  const workStartAfterRosterStart = moment(
                    element.in_time
                  ).diff(moment(element.details.start_time), "hours", true);
                  const workEndBeforeRosterEnd = moment(
                    element.details.end_time
                  ).diff(moment(element.out_time), "hours", true);
                  const inTimeInPercentage =
                    (workStartAfterRosterStart * 100) / element.details.hours;
                  const outTimeInPercentage =
                    (workEndBeforeRosterEnd * 100) / element.details.hours;
                  element.value = [
                    0,
                    Math.round(inTimeInPercentage),
                    100 - Math.round(outTimeInPercentage),
                    100,
                  ];

                  element.min = 0;
                  element.max = 100;
                  element.data = [
                    "Roster Start At " +
                      moment(element.details.start_time).format("hh:mm A"),
                    "Work Start At " +
                      moment(element.in_time).format("hh:mm A"),
                    "Work End At " + moment(element.out_time).format("hh:mm A"),
                    "Roster End At " +
                      moment(element.details.end_time).format("hh:mm A"),
                  ];
                  element.process2 = (dotsPos) => [
                    [dotsPos[0], dotsPos[1], { backgroundColor: "#17a2b8" }],
                    [dotsPos[1], dotsPos[2], { backgroundColor: "#28a745" }],
                    [dotsPos[2], dotsPos[3], { backgroundColor: "#17a2b8" }],
                  ];
                }
                // 0 will be the first value of the slider representing the roster start time
                // 100 will be the last value of the slider representing the roster end time

                // element.value = [0, 10, 80, 100];
                // element.data = [0, 1, 80, 100];
              }
            });
            this.getIdsForApproveAll(this.rosterWorks);
            this.loading = false;
            this.total = response.data.total;
            this.form = response.data.from;
            this.to = response.data.to;
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
        });
    },
    getIdsForApproveAll(rosterWorks) {
      let ids = [];
      rosterWorks.forEach((element) => {
        if (this.logedInUser.roles[0].name == "customer") {
          if (element.status == 0) {
            ids.push(element.id);
          }
        }
        if (this.logedInUser.roles[0].name == "admin") {
          if (element.status == 1 && element.verify == 0) {
            ids.push(element.id);
          }
        }
      });
      this.ids = ids;
    },
    async handleOk() {
      this.loading = true;
      let data = {
        ids: this.ids,
        verify: this.logedInUser.roles[0].name == "admin" ? 1 : "",
        status: this.logedInUser.roles[0].name == "customer" ? 1 : "",
      };
      await this.$store
        .dispatch("roster/approveAllTimesheets", data)
        .then((response) => {
          if (response.status_code == 200) {
            this.loading = false;
            this.getRosterWorkList();
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "All The Timesheets Approved Successfully."
            );
          }
        })
        .catch((error) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            error.response?.data?.message ||
              "Something went wrong, please try again later."
          );
          this.loading = false;
        });
    },
    getCompanies() {
      this.$store.dispatch("company/getCompanies").then((response) => {
        this.companies = response.data;
      });
    },
    async getCustomers(e) {
      if (this.query.company && this.query.company.id) {
        this.query.user_id = this.query.company.id;
        this.query.company_id = this.query.company.id;
      } else {
        this.query.user_id = "";
        this.query.company_id = "";
      }
      if (this.logedInUser.roles[0].name == "admin") {
        this.query.user_id = this.logedInUser.id;
        this.query.company_id = this.logedInUser.id;
      }
      await this.$store
        .dispatch("customer/getCustomers", this.query)
        .then((response) => {
          this.customers = response.data.data;
        });
    },

    rosterWorkStatusUpdate(item, status, verify) {
      let query = {
        id: item.id,
        customer_id: item.roster?.customer?.id,
        status: status,
        verify: verify,
        note: status === 2 ? this.declinedReason?.note : "",
      };
      if (status === 2 && item.note == "") {
        toast(
          "Error",
          "AlertTriangleIcon",
          "danger",
          "Please enter decline note."
        );
      } else {
        if (this.logedInUser.roles[0].name == "admin") {
          query.status = "";
          query.user_id = this.logedInUser.id;
          query.customer_id = "";
        }
        if (this.logedInUser.roles[0].name == "customer") {
          query.verify = "";
        }

        if (status === 1 || verify === 1) {
          this.$bvModal
            .msgBoxConfirm(
              "Please confirm that you want to approve the timesheet.",
              {
                title: "Please Confirm",
                size: "sm",
                buttonSize: "sm",
                okVariant: "success",
                okTitle: "YES",
                cancelTitle: "NO",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
              }
            )
            .then((value) => {
              if (value) {
                this.callUpdateApi(query);
              }
            })
            .catch((err) => {
              console.log(err, "cancel");
              // An error occurred
            });
        }
        if (status === 2) {
          this.callUpdateApi(query);
        }
      }
    },
    callUpdateApi(query) {
      this.$store
        .dispatch("roster/rosterWorkApprove", query)
        .then((response) => {
          if (response.status_code == 200) {
            this.getRosterWorkList();
            this.$refs["modal-prevent-closing"].hide();
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              response.message + " for " + response.data?.employee?.name
            );
          }
        })
        .catch((error) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            error.response?.data?.message ||
              "Something went wrong, please try again later."
          );
        });
    },
    declineRosterWork(item) {
      this.declinedReason = item;
      this.$refs["modal-prevent-closing"].show();
    },

    async handleDownload(type) {
      let query = {};
      if (this.logedInUser.roles[0].name == "customer") {
        query.customer_id = this.logedInUser.id;
      }
      if (this.logedInUser.roles[0].name === "admin") {
        query.user_id = this.logedInUser.id;
        query.company_id = this.logedInUser.id;
      }
      if (this.logedInUser.roles[0].name == "employee") {
        query.employee_id = this.logedInUser.id;
      }
      if (type === "filtered") {
        query.page = 1;
        query.per_page = this.total;
        if (this.rangeDate && this.rangeDate.length > 20) {
          // split the string in arrar
          let arr = this.rangeDate.split(" to ");
          query.date = arr[0];
          query.end = arr[1];
        }
        if (this.query.company && this.query.company.id) {
          query.user_id = this.query.company.id;
          query.company_id = this.query.company.id;
        }
        if (this.query.c_status) {
          query.status = this.query.c_status.id;
        }
        if (this.query.customer) {
          query.customer_id = this.query.customer.id;
        }
      }

      const response = await this.$store.dispatch(
        "roster/getTimesheetsForExport",
        query
      );
      const list = response.data;
      import("@/utils/Export2Excel").then((excel) => {
        const tHeader = this.exportHeader;
        const filterVal = this.exportVal;

        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: type + "-timesheets",
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === v.shift_type) {
            return this.makeFloat(v.shift_hours);
          }
          if (j === v.next_shift) {
            return this.makeFloat(v.shift_next_hours);
          }
          if (j === "shift") {
            return v.shift_type;
          }
          if (j === "week_status") {
            return v.weekend == 1 ? "Weekend" : "Weekday";
          }
          if (j === "date") {
            // dd-mm-yyyy date format using moment
            return this.formateDate(v[j]);
          } else if (j === "in_time" || j === "out_time") {
            // am pm time format using moment
            return this.formateTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },
  },
};
</script>
<style scoped>
.header-title {
  /* 32 medium */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 43px;
  /* identical to box height */

  /* Text main */

  color: #515b60;
}

.approval-card {
  border-radius: 24px;
}

button {
  margin: 0px 3px;
}
.status-btn {
  width: 95px !important;
  text-align: center;
}
.send {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}

.discard {
  background: rgba(255, 79, 93, 0.1);
  border: 1px solid #ff4f5d;
  border-radius: 5px;
  color: #ff4f5d;
}
.decline-text {
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
}
.data-text {
  /* 18 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  /* blue main */
  color: #01185e;
}

.bar-indicator .ind {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.bars {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.bar-indicator .roster {
  background-color: #17a2b8;
  margin: 0px 10px;
}
.bar-indicator .work {
  background-color: #28a745;
  margin: 0px 10px;
}

.bar-indicator .before {
  background-color: #ffc107;
  margin: 0px 10px;
}
.bar-indicator .after {
  background-color: #dc3545;
  margin: 0px 10px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
